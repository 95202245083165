import React, { useEffect  } from 'react';

const Ad = (props) => {
    useEffect(() => {

       /* try {
              (window.adsbygoogle = window.adsbygoogle || []).push({
               
                });
               
        }

        catch (e) {
            console.error('Ad error:' + e);
        }*/

    },[]);
    return (
        <div className="adunit"> 
        Ad Ad Ad Ad Ad AdAd AdAd AdAd AdAd AdAd AdAd AdAd AdAd AdAd AdAd AdAd AdAd AdAd AdAd AdAd Ad
                
        <div data-onpage="true"     data-adpath="/339474670,22847485332/02WS/Desktop_300x250">
             
        <ins className="adsbygoogle"
            
            data-ad-client="ca-pub-2706630587106567"
            data-ad-slot="1816548745"
            data-ad-format="rectangle"></ins>
        
        </div>
        </div>
       
        
    );
}

export default Ad; 